import { useCallback, useLayoutEffect, useMemo } from 'react';
import moment from 'moment'
import { Calendar, momentLocalizer, Navigate, NavigateAction, ToolbarProps, View, Views } from 'react-big-calendar';
import { TaskType } from '../taskList/views/CalendarTasksView/calendar/model';
import './calendar.scss';
import i18n from 'i18n';
import { DefaultViews, IEventCalendar, langCalendar } from './model';
import { useSelector } from 'react-redux';
import { selectCalendarComponentActiveView } from 'store/modules/task/calendarTask/calendarSelectors';
import { useActions } from 'utils/store/useActions';
import { calendarComponentActiveView, calendarComponentOnNavigate } from 'store/modules/task/calendarTask/calendarTaskActions';
import { EventCalendar } from './EventCalendar';
import { Theme, useMediaQuery } from '@material-ui/core';
import { Breakpoints } from 'utils/styles/constants';
import { CustomToolbar, CustomToolbarProps } from './CustomToolbar';
import { useWindowDimensions } from 'modules/shared/hooks';
import { ThemeProviderCustom } from 'modules/root/ThemeProviderCustom';

const localizer = momentLocalizer(moment);

type TaskCaledarProps = {
  data: Array<IEventCalendar>;
  openSideBar: (event: IEventCalendar) => void;
  height: number;
  viewsCustom?: View[];
  CustomToolbar?: React.ComponentType<ToolbarProps<IEventCalendar, object>>;
  activeViewCustom?: View;
  changeActiveView?: (view: View) => void
}

export const TaskCalendar = (props: TaskCaledarProps) => {
  const language = i18n.language;
  const activeView = useSelector(selectCalendarComponentActiveView);
  const actions = useActions({ 
    calendarComponentActiveView: calendarComponentActiveView,
    calendarComponentOnNavigate: calendarComponentOnNavigate
  });
  const isMobile = useMediaQuery(
      (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
      { noSsr: true },
  );
  const { width } = useWindowDimensions();

  const langCalendarRendered = useMemo(()=>{
    return isMobile ? {...langCalendar[language], showMore: (total: number) => `+${total}`,} : langCalendar[language]
  },[isMobile, language]);
  const activeViewMemo = useMemo(() => {
    return props?.activeViewCustom ? props?.activeViewCustom : activeView;
  },[activeView, props?.activeViewCustom]);

  const { defaultDate, messages, views } = useMemo(
    () => ({
      defaultDate: new Date(),
      messages: langCalendarRendered,
      views: props?.viewsCustom ?? DefaultViews
    }),
    [langCalendarRendered, props?.viewsCustom]
  )

  useLayoutEffect(()=> {
    if(width < 600 && activeViewMemo !== 'day' ){
        handleChangeView('day')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, actions]);

  const handleSelectedEvent = useCallback((event: IEventCalendar) => (
    props.openSideBar(event)
  ),[props]);

  const formats = {
    eventTimeRangeFormat: () => { 
      return "";
    },
  };
  
  const eventStyleGetter = (event: IEventCalendar, start: Date, end: Date, isSelected: boolean) => {
    const style = {
      opacity: event.taskType === TaskType.ScheduledTask ? 0.8 : 1,
      marginTop: 1,
      marginBottom: 1,
      padding: 0
    };
    return {
      style: style,
    };
  }

  const handleChangeView = (view: View) => {
    if(props?.changeActiveView){
      props.changeActiveView?.(view)
      return;
    }
    actions.current.calendarComponentActiveView(view);
  }

  return  (
    <ThemeProviderCustom>
      <Calendar
        culture={language}
        localizer={localizer}
        events={props.data}
        style={{ minHeight: props.height }}
        defaultDate={defaultDate}
        messages={messages}
        onSelectEvent={handleSelectedEvent}
        onNavigate={(newDate: Date, view: View, action: NavigateAction) => {
          if (action === Navigate.DATE && !views?.includes(Views.DAY)){
            return;
          }
          actions.current.calendarComponentOnNavigate({newDate, view, action});
        }}
        formats={formats}
        eventPropGetter={(eventStyleGetter)}
        onView={(view: View) =>{
            if(views.includes(view)){
              handleChangeView(view)
            }
          }
        } 
        view={activeViewMemo}
        views={views}
        defaultView={Views.WEEK}
        components={{
          event: _props => <EventCalendar {..._props} />,
          toolbar: _props => <CustomToolbar {..._props as CustomToolbarProps}/>
        }}
      />
    </ThemeProviderCustom>
  )
}