import { Button, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx"
import { Messages, Navigate, NavigateAction, View } from "react-big-calendar";
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import { OptionsDropdown } from "modules/shared/components/OptionsDropdown";
import { useCallback, useMemo, useState } from "react";
import { IconViewMap } from "./icons/icons";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
      flexGrow: 1,
      display: 'flex',
      gap: 8
  },
  toolbarContainer:{
    justifyContent: 'space-between'
  },
  toolbarMiddle:{
    display: 'flex',
    alignItems: 'center'
  },
  buttonBase: {
      padding: '4px 10px!important',
      border: `1px solid #9dc2ff!important`,
      '&:hover': {
        borderColor: 'var(--primary-dark)!important',
        backgroundColor: 'transparent!important',
      }
  },
  buttonArrow: {
    padding: '0!important',
    border: 'none!important'
  },
  label: {
      fontSize: 13,
      lineHeight: 1
  },
  viewDropdown: {
    alignItems: 'center',
    padding: '0px 32px 0px 12px',
    display: 'flex',
    minWidth: 50,
    borderColor: 'red',
    fontSize: 13,
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
}));


type ViewNamesGroupProps = {
  messages: Messages;
  onView:(view: View) => void;
  view: View;
  views: View[];
};

const RenderOption = ({ view, messages }: { view: View, messages: Messages }) => {
  const IconComponent = IconViewMap[view];

  return (
    <><IconComponent /> <Typography style={{paddingLeft: 4, fontSize: 13}}>{messages[view] as string}</Typography></>
  )
}

const ViewNamesGroup: React.FC<ViewNamesGroupProps> = ({ views: viewNames, view, messages, onView }) => {
    const [viewActive, setViewActive] = useState<View>(view);
    const options = useMemo(()=>(viewNames.map((view) => {
      return {
        value:view,
        title: <RenderOption view={view} messages={messages}/>
      }
    })),[viewNames, messages]);
    const classes = useStyles();

    const handleChangeView = useCallback((newValue?: View) => {
        if(!newValue) return;
        setViewActive(newValue);
        onView(newValue);
    }, [onView])

    return (
      <OptionsDropdown
          unsetValue={'0'}
          options={options}
          onValueChange={handleChangeView}
          value={viewActive}
          selectClasses={{ select: classes.viewDropdown}}
      />
    )
}

export type CustomToolbarProps = {
  date: Date;
  view: View;
  views: View[];
  label: string;
  localizer: { messages: Messages };
  onNavigate: (navigate: NavigateAction, date?: Date) => void;
  onView: (view: View) => void;
  children?: React.ReactNode;
  viewsCustom?: View[];
};

export const CustomToolbar: React.FC<CustomToolbarProps> = ({
  label,
  localizer: { messages },
  onNavigate,
  onView,
  view,
  views,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx('rbc-toolbar', 'home--custom-toolbar', classes.toolbarContainer)}>
      <span className="rbc-btn-group">
        <ViewNamesGroup
          view={view}
          views={views}
          messages={messages}
          onView={onView}
        />
      </span>
      <div className={classes.toolbarMiddle}>
        <IconButton aria-label="Copy" onClick={() => onNavigate(Navigate.PREVIOUS)} className={clsx(classes.buttonBase, classes.buttonArrow)}>
          <KeyboardArrowLeft style={{fontSize: 20}} />
        </IconButton>
        <span className={clsx('rbc-toolbar-label')}>{label}</span>
        <IconButton aria-label="Copy" onClick={() => onNavigate(Navigate.NEXT)} className={clsx(classes.buttonBase, classes.buttonArrow)}>
          <KeyboardArrowRight style={{fontSize: 20}}/>
        </IconButton>
      </div>
      <Button 
        variant="contained" 
        className={classes.buttonBase}
        size='small' 
        onClick={() => onNavigate(Navigate.TODAY)}
        color='primary'
        disableElevation
        >
            <Typography className={classes.label}>{messages?.today}</Typography>
      </Button>
    </div>
  )
}
