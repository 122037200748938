import { combineReducers } from 'redux';
import { CALENDAR_COMPONENT_ACTIVE_VIEW, CALENDAR_COMPONENT_ON_NAVIGATE, CALENDAR_FILTER, CALENDAR_FILTER_LOCATION_RESET, CALENDAR_HOME_COMPONENT_ACTIVE_VIEW, CALENDAR_LOCATION_IDS_SELECTED, CALENDAR_TASKS_SCHEDULED_RESET, CalendarTaskActions, EVENT_ID_MODAL, RESET_EVENT_CALENDAR_DETAIL, SET_CALENDAR_EVENT_TO_REMOVE_ID, STORE_TASK_MODAL_SET_PARENT_TASK_ID } from './calendarTaskActions';
import { CalendarApiActions, requestGetCalendarEvent, requestCalendarCreateEvent, requestCalendarTasksRegular, requestCalendarTasksScheduled, requestCalendarTasksUser, requestUpdateCalendarEvent, requestDeleteCalendarEvent, requestCalendarEvent } from 'api/task/calendarTask/calendaTaskActions';
import { ItemsById } from 'model/entity';
import { CalendarEvent, CalendarEventShort, CalendarTaskRegularResponse, CalendarTaskScheduledResponse, CalendarTaskUserResponse } from 'api/task/calendarTask/calendaTaskModel';
import { itemsByIds } from 'utils/helpers/itemsByIds';
import { insertSavingOrder } from 'utils/helpers/collections';
import { View, Views } from "react-big-calendar";
import { ICalendarComponentOnNavigate } from './calendarTaskModel';
import { DefaultValuesFilterCalendar } from 'api/task/taskFilter/taskFilterModel';

const initialState = {
  calendarTasksById: {},
  calendarTasksOrder: [],
  calendarTasksScheduledById: {},
  calendarTasksScheduledOrder: [],
  calendarTasksAutomatedById: {},
  calendarTasksAutomatedOrder: [],
  calendarEventById: {},
  calendarEventOrder: [],
  calendarTasksFetching: false,

  activeView: Views.MONTH,
  activeHomeView: Views.WEEK,
  onNavigate: null,
  parentTaskByLocationModalActiveId: null,
  locationIdsSelected: [],

  calendarEventCreating: false,
  loadingById: {},
  updatingById: {},

  eventToRemove: null,
  eventToRemoveFetching: false,
  eventModalActiveId: null,
  calendarEventDetail: null,

  calendarFilter: DefaultValuesFilterCalendar
};

function calendarTasksById(
  state: ItemsById<CalendarTaskRegularResponse> = initialState.calendarTasksById,
  action: CalendarApiActions,
  ): ItemsById<CalendarTaskRegularResponse> {
    if( action.type === requestCalendarTasksRegular.successType){
      return itemsByIds(action.payload);
    }
    return state;
}

function calendarTasksOrder(
  state: Array<string> = initialState.calendarTasksOrder,
  action: CalendarApiActions,
): Array<string> {
  if (action.type === requestCalendarTasksRegular.successType) {
    return insertSavingOrder(state, action.payload.map((item: { id: any; }) => item.id));
  }
  return state;
}

function calendarTasksScheduledById(
  state: ItemsById<CalendarTaskScheduledResponse> = initialState.calendarTasksScheduledById,
  action: CalendarApiActions | CalendarTaskActions,
): ItemsById<CalendarTaskScheduledResponse> {
  switch (action.type) {
    case requestCalendarTasksScheduled.successType:
      return itemsByIds(action.payload);
    case CALENDAR_TASKS_SCHEDULED_RESET:
      return {}
    default:
      return state;
  }
}

function calendarTasksScheduledOrder(
  state: Array<string> = initialState.calendarTasksScheduledOrder,
  action: CalendarApiActions | CalendarTaskActions,
): Array<string> {
  switch (action.type) {
    case requestCalendarTasksScheduled.successType:
      return insertSavingOrder(state, action.payload.map((item: { id: any; }) => item.id));
    case CALENDAR_TASKS_SCHEDULED_RESET:
      return [];
    default:
      return state;
  }
}

function calendarTasksAutomatedById(
  state: ItemsById<CalendarTaskUserResponse> = initialState.calendarTasksAutomatedById,
  action: CalendarApiActions,
): ItemsById<CalendarTaskUserResponse> {
  if( action.type === requestCalendarTasksUser.successType ) {
    return itemsByIds(action.payload);
  }
  return state;
}

function calendarTasksAutomatedOrder(
  state: Array<string> = initialState.calendarTasksAutomatedOrder,
  action: CalendarApiActions,
): Array<string> {
  if (action.type === requestCalendarTasksUser.successType) {
    return insertSavingOrder(state, action.payload.map((item: { id: any; }) => item.id));
  }
  return state;
}

function calendarComponentActiveView(
  state: View = initialState.activeView,
  action: CalendarTaskActions,
): View {
  if (action.type === CALENDAR_COMPONENT_ACTIVE_VIEW) {
    return action.payload;
  }
  return state;
}

function calendarHomeComponentActiveView(
  state: View = initialState.activeHomeView,
  action: CalendarTaskActions,
): View {
  if (action.type === CALENDAR_HOME_COMPONENT_ACTIVE_VIEW) {
    return action.payload;
  }
  return state;
}

function calendarComponentOnNavigate(
  state: ICalendarComponentOnNavigate | null = initialState.onNavigate,
  action: CalendarTaskActions,
): ICalendarComponentOnNavigate | null {
  if (action.type === CALENDAR_COMPONENT_ON_NAVIGATE) {
    return action.payload;
  }
  return state;
}

export function parentTaskByLocationModalActiveId(
  state: string | null = initialState.parentTaskByLocationModalActiveId, action: CalendarTaskActions,
): string | null {
  if (action.type === STORE_TASK_MODAL_SET_PARENT_TASK_ID){
    return action.payload;

  }
  return state;
}

function calendarTasksFetching(
    state: boolean = initialState.calendarTasksFetching,
    action: CalendarApiActions,
): boolean {
  switch (action.type) {
    case requestCalendarTasksScheduled.initType:
    case requestCalendarTasksRegular.initType:
    case requestCalendarTasksUser.initType:
    case requestCalendarEvent.initType:
        return true;
    case requestCalendarTasksRegular.successType:
    case requestCalendarTasksRegular.errorType:
    case requestCalendarTasksScheduled.successType:
    case requestCalendarTasksScheduled.errorType:
    case requestCalendarTasksUser.successType:
    case requestCalendarTasksUser.errorType:
    case requestCalendarEvent.errorType:
        return false;
    default:
        return state;
  }
}

export function locationIdsSelected(
  state: string[] = initialState.locationIdsSelected, action: CalendarTaskActions,
): string[] {
  switch (action.type) {
    case CALENDAR_FILTER_LOCATION_RESET:
      return [];
    case  CALENDAR_LOCATION_IDS_SELECTED:
      return action.payload;
    default:
      return state;
  }
}

function calendarEventCreating(state = initialState.calendarEventCreating, action: CalendarApiActions): boolean {
  switch (action.type) {
      case requestCalendarCreateEvent.initType:
          return true;
      case requestCalendarCreateEvent.errorType:
      case requestCalendarCreateEvent.successType:
          return false;
      default:
          return state;
  }
}

function calendarEventById(
  state: ItemsById<CalendarEvent | CalendarEventShort> = initialState.calendarEventById,
  action: CalendarApiActions | CalendarTaskActions,
): ItemsById<CalendarEvent | CalendarEventShort> {
  switch (action.type) {
    case requestCalendarEvent.successType:
        return itemsByIds(action.payload);  
    case requestDeleteCalendarEvent.successType: {
        const currentState = {...state};
        delete currentState[action.payload.id]
        return currentState
    }
    case CALENDAR_TASKS_SCHEDULED_RESET:
      return {}
    default:
      return state;
  }
}

function calendarEventDetail(
  state: CalendarEvent | null = initialState.calendarEventDetail,
  action: CalendarApiActions | CalendarTaskActions,
): CalendarEvent | null {
  switch (action.type) {
      case requestGetCalendarEvent.successType:
          return action.payload;
      case RESET_EVENT_CALENDAR_DETAIL:
      case requestUpdateCalendarEvent.successType:
          return null;
      default:
          return state;
  }
}

function calendarEventOrder(
  state: Array<string> = initialState.calendarEventOrder,
  action: CalendarApiActions | CalendarTaskActions,
): Array<string> {
  switch (action.type) {
    case requestCalendarEvent.successType:
      return insertSavingOrder(state, action.payload.map((item: { id: any; }) => item.id));
    case CALENDAR_TASKS_SCHEDULED_RESET:
      return [];
    default:
      return state;
  }
}

function loadingById(
  state: ItemsById<boolean> = initialState.loadingById,
  action: CalendarApiActions,
): ItemsById<boolean> {
  switch (action.type) {
      case requestGetCalendarEvent.initType:
          return { ...state, [action.payload]: true };
      case requestGetCalendarEvent.successType:
      case requestGetCalendarEvent.errorType:
          return { ...state, [action.payload.id]: false };
      default:
          return state;
  }
}

function updatingById(
  state: ItemsById<boolean> = initialState.updatingById,
  action: CalendarApiActions,
): ItemsById<boolean> {
  switch (action.type) {
      case requestUpdateCalendarEvent.initType:
          return { ...state, [action.payload.id]: true };
      case requestUpdateCalendarEvent.successType:
      case requestUpdateCalendarEvent.errorType:
          return { ...state, [action.payload.id]: false };
      default:
          return state;
  }
}

export function eventToRemove(
  state = initialState.eventToRemove,
  action: CalendarTaskActions | CalendarApiActions,
): string | null {
  switch (action.type) {
    case SET_CALENDAR_EVENT_TO_REMOVE_ID:
        return action.payload;
    case requestDeleteCalendarEvent.successType:
        return null;
    default:
        return state;
  }
}

function eventToRemoveFetching(state = initialState.calendarEventCreating, action: CalendarApiActions): boolean {
  switch (action.type) {
      case requestDeleteCalendarEvent.initType:
          return true;
      case requestDeleteCalendarEvent.errorType:
      case requestDeleteCalendarEvent.successType:
          return false;
      default:
          return state;
  }
}

export function eventModalActiveId(
  state: string | null = initialState.eventModalActiveId, action: CalendarTaskActions,
): string | null {
  if (action.type === EVENT_ID_MODAL){
    return action.payload;

  }
  return state;
}

export function calendarFilter(
  state: {filter?: string, tags: string[]} = initialState.calendarFilter, action: CalendarTaskActions,
): {filter?: string, tags: string[]} {
  switch (action.type) {
    case CALENDAR_FILTER_LOCATION_RESET:
      return { filter: undefined, tags: []};
    case  CALENDAR_FILTER:
      return action.payload;
    default:
      return state;
  }
}

export const calendarReducer = combineReducers({
    calendarTasksById,
    calendarTasksOrder,
    calendarComponentActiveView,
    calendarComponentOnNavigate,
    parentTaskByLocationModalActiveId,
    calendarTasksScheduledOrder,
    calendarTasksScheduledById,
    calendarTasksFetching,
    locationIdsSelected,
    calendarTasksAutomatedById,
    calendarTasksAutomatedOrder,
    calendarEventCreating,
    calendarEventById,
    calendarEventOrder,
    loadingById,
    updatingById,
    eventToRemove,
    eventToRemoveFetching,
    eventModalActiveId,
    calendarEventDetail,
    calendarFilter,
    calendarHomeComponentActiveView
});
