export enum FeatureFlags {
    LegacyNotificationFeed = 'legacy_notification_feed',
    AutoExpandLocationView = 'auto_expand_location_view',
    TaskCreatePublishDate = 'task_create_publish_date',
    TaskCreateRecurrence = 'task_create_recurrence',
    TaskRecurrenceEveryWeekday = 'task_recurrence_every_weekday',
    TaskRecurrenceBiWeekly = 'task_recurrence_bi_weekly',
    TaskRecurrenceMonthlyByDay = 'task_recurrence_monthly_by_day',
    TaskRecurrenceAnnually = 'task_recurrence_annually',
    FilesChunkUpload = 'files_chunk_upload',
    ScheduledTasks = 'scheduled_tasks',
    EditScheduledTask = 'edit_scheduled_task',
    ViewScheduledTask = 'view_scheduled_task',
    DeleteScheduledTask = 'delete_scheduled_task',
    ManagerAccessToTaskEditing = 'manager_access_scheduled_task_by_location',
    Admin2_UserList = 'admin2_user_list',
    Admin2_UserProfile = 'admin2_user_profile',
    FilesCDN = 'enable_cdn_auth_token',
    UserSearchByFullName = 'search_by_fullname',
    MaintenanceEnabled = 'maintenance_mode_on',
    ActivateUsersBulk = 'activate_users_bulk',
    DeactivateUsersBulk = 'deactivate_users_bulk',
    BulkImportUsers = 'bulk_import_users',
    SingleRoleStreamChats = 'single_role_stream_chats',
    TaskWatchers = 'task_watchers',
    TaskLabel = 'task_label',
    OverdueTaskColumns = 'overdue_columns',
    TaskLocationRangeDate = 'task_location_range_date',
    ChatSearchEnabled = 'chat_search',
    TaksExpirable = 'expirable_tasks',
    TaskClone = 'clone_tasks',
    TaskTags = 'task_tags',
    TasksSearchByStore = 'tasks_search_by_store',
    UserImpersonation = 'user_impersonation',
    tasksCustomChecklistOptions = 'tasks_custom_checklist_options',
    ConnectSearchUsers = 'connect_search_users',
    ConnectShowChannelCreator = 'connect_show_channel_creator',
    TasksSearch = 'tasks_search',
    TasksTemplate = 'tasks_template',
    ScheduledTaskHistory = 'scheduled_task_history',
    BulkDeactivateUsers = 'bulk_deactivate_users_csv',
    TasksFilterPersistence = 'store_user_filters',
    NewCompanyProfile = 'new_company_profile',
    TasksLocationViewTagFilter = 'tasks_location_view_tag_filter',
    ConnectShowChannelAttachments = 'connect_show_channel_attachments',
    Language = 'language_select',
    EditCompanyLogo = 'edit_company_logo',
    TaskReviewByParentTask = 'task_review_by_parent_task',
    CustomInputConnect = 'custom_input_connect',
    ConnectFormatHyperlink = 'connect_format_hyperlink',
    ConnectFormatBullet = 'connect_format_bullet',
    TasksEnableDocumentPreview = 'tasks_enable_document_preview',
    tasksDeleteStoreTask = 'tasks_delete_store_task',
    TasksScheduledTaskRemoveStore = 'tasks_scheduled_task_remove_store',
    ConnectPinMessage = 'connect_pin_message',
    TaskGallery = 'task_gallery',
    LaunchPage = 'launch_page',
    NtfTaskDueReminder = 'ntf_task_due_reminder',
    TasksAutomationMyTasks = 'tasks_automation_my_tasks',
    TasksCalendarView = 'tasks_calendar_view',
    CalendarEvents = 'calendar_events',
    TaskChecklistDragDrop = 'task_checklist_drag_drop',
    LaunchPageShowCalendar = 'launch_page_show_calendar',
    taskConversations = 'task_conversations',
    TasksReassignCreator = 'tasks_reassign_creator',
    TasksBulkImport = 'tasks_bulk_import',
    LaunchPageReorder = 'launch_page_reorder',
    TasksChecklistAudit = 'tasks_checklist_audit',
    CreatorFilter = 'task_creator_filter',
    Integrations = 'integrations',
    AISidekick = 'ai_sidekick',
    TasksCalendarViewHideScheduledButton= 'tasks_calendar_view_hide_scheduled_button',
    TasksLocationViewDownloadReport = 'tasks_location_view_download_report',
    connectChannelImage = 'connect_channel_image',
    ConnectPolls = 'connect_polls',
    ConnectAudioMessages = 'connect_audio_messages',
    TasksCalendarViewEnableToggle = 'tasks_calendar_view_enable_toggle',
    TaskPriority = 'task_priority',
    ConnectMemberCountInternal = 'connect_member_count_internal'
}
