import { getChannelMemberCount } from 'api/getStream/getStreamActions';
import { useCallback } from 'react'
import { useSelector } from 'react-redux';
import { selectChannelCountMember, selectisChannelCountMemberFetching } from 'store/modules/getStream/getStreamSelectors';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { useActions } from 'utils/store/useActions';

export const useCountMembers = (channelId: string) => {
    const isConnectMemberCountInternalEnabled = useFeatureState(FeatureFlags.ConnectMemberCountInternal);
    const channelCountMember = useSelector(selectChannelCountMember);
    const isChannelCountMemberFetching = useSelector(selectisChannelCountMemberFetching);
    const actions = useActions({ getChannelMemberCount: getChannelMemberCount.init });
    
    const getCountMembers = useCallback( () => {
        if(isConnectMemberCountInternalEnabled){
            actions.current.getChannelMemberCount(channelId);
        }
    },[channelId, isConnectMemberCountInternalEnabled, actions]);

    return ({
        channelCountMember,
        isChannelCountMemberFetching,
        getCountMembers
    })
}
