import { AutomationTaskStatusFilter } from "api/task/automationTask/automationTaskModel";
import { ParentTaskStatusFilter } from "api/task/parentTask/parentTaskModel";
import { StoreTaskStatusFilter } from "api/task/storeTask/storeTaskModel";
import i18n from "i18n";

const storeTaskStatusToLabelMap: Record<StoreTaskStatusFilter, string> = {
    [StoreTaskStatusFilter.Incomplete]: `${i18n.t('Incomplete (Not Approved, Declined, Incomplete, Overdue)')}`,
    [StoreTaskStatusFilter.Expired]: `${i18n.t('Expired')}`,
    [StoreTaskStatusFilter.Completed]: `${i18n.t('Completed Tasks')}`,
    [StoreTaskStatusFilter.Overdue]: `${i18n.t('Overdue')}`,
    [StoreTaskStatusFilter.Submitted]: `${i18n.t('Submitted Tasks')}`,
    [StoreTaskStatusFilter.CompletedOverdue]: `${i18n.t('Completed Overdue')}`,
    [StoreTaskStatusFilter.SubmittedOverdue]: `${i18n.t('Submitted Overdue')}`,
};

const parentTaskStatusToLabelMap: Record<ParentTaskStatusFilter, string> = {
    [ParentTaskStatusFilter.Incomplete]: `${i18n.t('Incomplete (Declined-Not Approved, Incomplete)')}`,
    [ParentTaskStatusFilter.Expired]: `${i18n.t('Expired')}`,
    [ParentTaskStatusFilter.Completed]: `${i18n.t('Completed Tasks')}`,
    [ParentTaskStatusFilter.Overdue]: `${i18n.t('Overdue')}`,
    [ParentTaskStatusFilter.Submitted]: `${i18n.t('Submitted Tasks')}`,
    [ParentTaskStatusFilter.CompletedOverdue]: `${i18n.t('Completed Overdue')}`,
    [ParentTaskStatusFilter.SubmittedOverdue]: `${i18n.t('Submitted Overdue')}`
};

export type FilterOption<T extends string> = {
    value: T | string;
    title: string | React.ReactNode;
};

export const allTasksSyntheticValue = `${i18n.t('all')}`;

export const getStoreTaskFilterOptions = (isOverdueColumnsFeatureEnabled: boolean, isExpirableColumnsFeatureEnabled: boolean): FilterOption<StoreTaskStatusFilter>[] => {
    return [
        { value: allTasksSyntheticValue, title: `${i18n.t('All Tasks')}` },
        ...Object.values(StoreTaskStatusFilter).map((value) => ({
            value,
            title: `${i18n.t(storeTaskStatusToLabelMap[value])}`,
        })).filter((x) => {
            if(x.title === parentTaskStatusToLabelMap[ParentTaskStatusFilter.Expired]){
                return isExpirableColumnsFeatureEnabled;
            } else if (isOverdueColumnsFeatureEnabled) {
                return true;
            } else if (x.title === storeTaskStatusToLabelMap[StoreTaskStatusFilter.CompletedOverdue]
                || x.title === storeTaskStatusToLabelMap[StoreTaskStatusFilter.SubmittedOverdue]) {
                return false;
            }
            return true;
        }),
    ];
};

export const getParentTaskFilterOptions = (isOverdueColumnsFeatureEnabled: boolean, isExpirableColumnsFeatureEnabled: boolean): FilterOption<ParentTaskStatusFilter>[] => {
    return [
        { value: allTasksSyntheticValue, title: `${i18n.t('All Tasks')}` },
        ...Object.values(ParentTaskStatusFilter).map((value) => ({
            value,
            title: `${i18n.t(parentTaskStatusToLabelMap[value])}`
        })).filter((x) => {
            if(x.title === parentTaskStatusToLabelMap[ParentTaskStatusFilter.Expired]){
                return isExpirableColumnsFeatureEnabled;
            } else if (isOverdueColumnsFeatureEnabled) {
                return true;
            } else if (x.title === parentTaskStatusToLabelMap[ParentTaskStatusFilter.CompletedOverdue]
                || x.title === parentTaskStatusToLabelMap[ParentTaskStatusFilter.SubmittedOverdue]) {
                return false;
            }
            return true;
        }),
    ];
};

export const getAutomationTaskFilterOptions = (): FilterOption<AutomationTaskStatusFilter>[] => {
    return [
        { value: allTasksSyntheticValue, title: `${i18n.t('All Tasks')}` },
        ...Object.values(AutomationTaskStatusFilter).map((value) => ({
            value,
            title: `${i18n.t(parentTaskStatusToLabelMap[value])}`,
        }))
    ];
};