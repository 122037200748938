import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { PageLoader } from 'components/router/PageLoader';
import { WelcomePageLayout } from './WelcomePageLayout';
import { t } from 'i18next';
import { Launch } from './launch/Launch';
import { Notification } from './notification/Notification';
import { LaunchCover } from './launchCover/LaunchCover';
import { CalendarHome } from './calendar/CalendarHome';
import { Fade, Tooltip } from '@material-ui/core';
import { useMemo } from 'react';
import { TabsButton } from 'components/tabsButton/TabsButton';
import launch from 'images/rocket.png';
import calendar from 'images/calendar.png';
import bell from 'images/bell.png';
import activityFeed from 'images/activity-feed.png';
import { Trans } from 'react-i18next';

export const WelcomePage = () => {
    const currentUser = useCurrentUser();

    if (!currentUser) {
        return (
            <PageLoader />
        );
    }


    return (
        <WelcomePageLayout
            header={`${t('Welcome')}, ${currentUser?.firstName}!`}
            launchCover={<LaunchCover />}
        >
            <WelcomeSectionTab />
        </WelcomePageLayout>
    );
};

enum NameTab {
    LAUNCH = 'Launch',
    CALENDAR = 'Calendar',
    NOTIFICATION = 'Notification',
    ACTIVITYFEED = 'ActivityFeed'
}
const WelcomeSectionTab = () => {
    const useTabMemo = useMemo(()=>([
        {name: NameTab.LAUNCH, children: <Launch />, label: IconTabsMap[NameTab.LAUNCH]},
        {name: NameTab.CALENDAR, children: <CalendarHome />, label: IconTabsMap[NameTab.CALENDAR]},
        {name: NameTab.NOTIFICATION, children: <Notification />, label: IconTabsMap[NameTab.NOTIFICATION]},
    ]), []);

    return (
        <TabsButton 
            tabs={useTabMemo}
            isReRenderChildren
            variant="standard"
            centered
        />
    )
}


const IconsTabsWelcome = ({children, title}: {children: React.ReactNode, title: string}) => (
    <Tooltip
        title={<Trans>{title}</Trans>} arrow 
        placement='top' 
        TransitionComponent={Fade}>
        <span>
            {children}
        </span>
    </Tooltip>
)

const IconTabsMap = {
    [NameTab.LAUNCH]: <IconsTabsWelcome title={NameTab.LAUNCH}><img src={launch} width={32} height={32} alt="launch-header"/></IconsTabsWelcome>,
    [NameTab.ACTIVITYFEED]: <IconsTabsWelcome title={NameTab.ACTIVITYFEED}><img src={activityFeed} width={32} height={32} alt="launch-header"/></IconsTabsWelcome>,
    [NameTab.CALENDAR]: <IconsTabsWelcome title={NameTab.CALENDAR}><img src={calendar} width={32} height={32} alt="launch-header"/></IconsTabsWelcome>,
    [NameTab.NOTIFICATION]: <IconsTabsWelcome title={NameTab.NOTIFICATION}><img src={bell} width={32} height={32} alt="launch-header"/></IconsTabsWelcome>,
}
