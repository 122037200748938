import { Box } from '@material-ui/core';
import { useGetAttachmentUrl } from 'modules/task/attachments/components/AttachmentThumbnail/AttachmentThumbnail';
import { useCallback, useMemo } from 'react'
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { useLaunchCoverStyles } from './styles';
import { useActions } from 'utils/store/useActions';
import { launchDeleteModalOpen } from 'api/launch/launchActions';
import { useSelector } from 'react-redux';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { openLaunchCoverEditor } from 'store/modules/launch/launchActions';
import { ActionButton } from 'components/button/actionButton/ActionButton';
import { IAction } from 'components/button/actionButton/model';

export const LaunchImg = ({ launchPageCover }: { launchPageCover: string }) => {
  const classes = useLaunchCoverStyles();
  const launchCoverImageUrlGetterAtt = useGetAttachmentUrl();
  const launchurl = useMemo(() => (launchCoverImageUrlGetterAtt(launchPageCover)), [launchPageCover, launchCoverImageUrlGetterAtt]);
  const actions = useActions({ openModalDeleteLaunch: launchDeleteModalOpen, openLaunchCoverEditor: openLaunchCoverEditor });
  const canCreateDelete = useSelector(selectHasAppAccess(AppName.AdminCompanyProfile));

  const handleLaunchImgDelete = useCallback(() => {
    actions.current.openModalDeleteLaunch({ launch: { id: 'isLaunchCover', caption: 'Cover photo', isLaunchCover: true }, isOpen: true })
  }, [actions]);

  const launchCoverImgEditHandler = useCallback(() => actions.current.openLaunchCoverEditor(true), [actions]);

  return (
    <Box position="relative" width="100%" height="100%" className={classes.launchCoverImg} >
      <Box className={classes.launchCoverImg} style={{backgroundImage: `url(${launchurl})` }}/>
      <ActionsLaunchImage canCreateDelete={canCreateDelete} onLaunchCoverImgEdit={launchCoverImgEditHandler} onLaunchImgDelete={handleLaunchImgDelete}/>
    </Box>
  )
}

const ActionsLaunchImage: React.FC<{onLaunchCoverImgEdit:() => void, onLaunchImgDelete: () => void, canCreateDelete: boolean}> = (props) => {
    const classes = useLaunchCoverStyles();
    const items: Array<IAction<string>> = [
        {
            name: '',
            action: props.onLaunchCoverImgEdit,
            isAvailable: true,
            Icon: EditIcon,
            key:'editLaunchCover'
        },
        {
            name: '',
            action: props.onLaunchImgDelete,
            isAvailable: true,
            Icon: DeleteIcon,
            key:'deleteLaunchCover'
        },
    ];

    if(!props.canCreateDelete) return <></>;

    return (
      <Box className={classes.actionsLaunchImage}>
        <ActionButton
            autoHide
            disabled={false}
            entity={'hey'}
            width={'auto'}
            items={items}
            marginLeft={-15}
        />
        </Box>
    );
};
