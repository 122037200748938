import { StyledLayoutBodyColumn } from '../WelcomePageLayout';
import { Box, Typography } from '@material-ui/core';
import { CalendarTasks } from 'modules/task/taskList/views/CalendarTasksView/calendar/CalendarTasks';
import { useCalendarTasksData } from 'modules/task/taskList/views/CalendarTasksView/calendar/useCalendarTasksData';
import { View, Views } from 'react-big-calendar';
import { Trans } from 'react-i18next';
import { StoreTaskView } from 'modules/task/singleTask/storeTaskView/StoreTaskView';
import { TaskByLocationsSideOut } from 'modules/task/taskList/views/CalendarTasksView/TaskByLocationsSideOut';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { EventSideOut } from 'modules/task/taskCalendar/eventSideOut/EventSideOut';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { selectCalendarHomeComponentActiveView } from 'store/modules/task/calendarTask/calendarSelectors';
import { calendarHomeComponentActiveView } from 'store/modules/task/calendarTask/calendarTaskActions';
import { useActions } from 'utils/store/useActions';

export const CalendarHome = () => {
    const data = useCalendarTasksData();
    const isLaunchPageShowCalendarEnabled = useFeatureState(FeatureFlags.LaunchPageShowCalendar);
    const activeView = useSelector(selectCalendarHomeComponentActiveView);
    const actions = useActions({ 
      calendarComponentActiveView: calendarHomeComponentActiveView,
    });

    if(!isLaunchPageShowCalendarEnabled) return <></>;

    const handleChangeActiveView = (view: View) => {
      actions.current.calendarComponentActiveView(view);
    }

    return(
        <Suspense fallback={'loading...'}>
            <StyledLayoutBodyColumn>
              <Box display="flex" gridGap={8} alignItems="center">
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}><Trans>Calendar</Trans></Typography>
              </Box>
              <Box style={{ overflow: 'auto' }} mt={2} className="home-page-calendar">
                  <CalendarTasks
                      data={data}
                      height={500}
                      viewsCustom={[Views.WEEK, Views.DAY]}
                      activeViewCustom={activeView}
                      changeActiveView={handleChangeActiveView}
                  />
              </Box>
              <StoreTaskView />
              <TaskByLocationsSideOut />
              <EventSideOut />
            </StyledLayoutBodyColumn>
        </Suspense>
    )
}
