import React, { useEffect } from 'react';
import { Theme, ThemeProvider,  createTheme } from '@material-ui/core/styles';
import { baseTheme } from './theme';
import { useSelector } from 'react-redux';
import { selectUserCompany } from 'store/modules/company/companySelector';

export const ThemeProviderCustom = ({ children }: {children: React.ReactNode}) => {
    const companyInformation = useSelector(selectUserCompany);
    
    const customTheme: Theme = ((theme: Theme) => {
        return createTheme({
            ...theme,
            palette: {
                primary: {
                    ...theme.palette.primary,
                    dark: companyInformation?.primaryColor || theme.palette.primary.dark,
                },
                secondary: {
                    ...theme.palette.secondary,
                    light: companyInformation?.secondaryColor || theme.palette.secondary.light
                }
            }
        });
    })(baseTheme);
    useEffect(() => {
        if(document?.documentElement){
            document.documentElement.style.setProperty("--primary-dark", customTheme.palette.primary.dark);
            document.documentElement.style.setProperty("--primary-dark-contrastText", customTheme.palette.getContrastText(customTheme.palette.primary.dark));
            document.documentElement.style.setProperty("--secondary-light", customTheme.palette.secondary.light);
            
        }
    }, [customTheme]);
    return (
        <ThemeProvider theme={customTheme}>
            {children}
        </ThemeProvider>
    );
};
