import { createSingleAction } from 'utils/store/actionUtils';
import { ICalendarComponentOnNavigate } from './calendarTaskModel';
import { View } from 'react-big-calendar';
import { CalendarRangeDate } from 'api/task/calendarTask/calendaTaskModel';

export const GET_CALENDAR_TASK = 'task/calendar/GET_CALENDAR_TASKS';
export const getCalendarTasks = createSingleAction<CalendarRangeDate, typeof GET_CALENDAR_TASK>(
    GET_CALENDAR_TASK,
);

export const CALENDAR_COMPONENT_ACTIVE_VIEW = 'task/calendar/COMPONENT_ACTIVE_VIEW';
export const calendarComponentActiveView = createSingleAction<View, typeof CALENDAR_COMPONENT_ACTIVE_VIEW>(
    CALENDAR_COMPONENT_ACTIVE_VIEW,
);

export const CALENDAR_HOME_COMPONENT_ACTIVE_VIEW = 'task/calendarHome/COMPONENT_ACTIVE_VIEW';
export const calendarHomeComponentActiveView = createSingleAction<View, typeof CALENDAR_HOME_COMPONENT_ACTIVE_VIEW>(
    CALENDAR_HOME_COMPONENT_ACTIVE_VIEW,
);

export const CALENDAR_COMPONENT_ON_NAVIGATE = 'task/calendar/COMPONENT_ON_NAVIGATE';
export const calendarComponentOnNavigate = createSingleAction<ICalendarComponentOnNavigate, typeof CALENDAR_COMPONENT_ON_NAVIGATE>(
    CALENDAR_COMPONENT_ON_NAVIGATE,
);

export const STORE_TASK_MODAL_SET_PARENT_TASK_ID = 'task/calendar/MODAL_SET_PARENT_TASK_ID';
export const parentTaskByLocationModalActiveId = createSingleAction<string | null, typeof STORE_TASK_MODAL_SET_PARENT_TASK_ID>(
    STORE_TASK_MODAL_SET_PARENT_TASK_ID,
);

export const CALENDAR_LOCATION_IDS_SELECTED = 'task/calendar/LOCATION_IDS_SELECTED';
export const calendarComponentLocationIdsSelected = createSingleAction<string[], typeof CALENDAR_LOCATION_IDS_SELECTED>(
    CALENDAR_LOCATION_IDS_SELECTED,
);

export const SET_CALENDAR_EVENT_TO_REMOVE_ID = 'task/calendar/SET_CALENDAR_EVENT_TO_REMOVE_ID';
export const setCalendarEventToRemove = (
    createSingleAction<string | null, typeof SET_CALENDAR_EVENT_TO_REMOVE_ID>(SET_CALENDAR_EVENT_TO_REMOVE_ID)
);
export const CALENDAR_TASKS_SCHEDULED_RESET = 'task/calendar/scheduled/RESET';
export const resetTasksScheduled = createSingleAction<void, typeof CALENDAR_TASKS_SCHEDULED_RESET>(
    CALENDAR_TASKS_SCHEDULED_RESET,
);

export const EVENT_ID_MODAL = 'task/calendar/EVENT_ID_MODAL';
export const eventModalActiveId = createSingleAction<string | null, typeof EVENT_ID_MODAL>(
    EVENT_ID_MODAL,
);

export const RESET_EVENT_CALENDAR_DETAIL = 'task/calendar/RESET_EVENT_CALENDAR_DETAIL';
export const resetEventCalendarDetail = createSingleAction<void, typeof RESET_EVENT_CALENDAR_DETAIL>(
    RESET_EVENT_CALENDAR_DETAIL,
);

export const CALENDAR_FILTER = 'task/calendar/CALENDAR_FILTER';
export const calendarFilter = createSingleAction<{filter?: string, tags: string[]}, typeof CALENDAR_FILTER>(
    CALENDAR_FILTER,
);

export const CALENDAR_FILTER_LOCATION_RESET = 'task/calendar/scheduled/CALENDAR_FILTER_LOCATION_RESET';
export const resetFilterLocationCalendar = createSingleAction<void, typeof CALENDAR_FILTER_LOCATION_RESET>(
    CALENDAR_FILTER_LOCATION_RESET,
);

export type CalendarTaskActions = (
    ReturnType<typeof getCalendarTasks>
    | ReturnType<typeof calendarComponentActiveView>
    | ReturnType<typeof calendarComponentOnNavigate>
    | ReturnType<typeof parentTaskByLocationModalActiveId>
    | ReturnType<typeof calendarComponentLocationIdsSelected>
    | ReturnType<typeof setCalendarEventToRemove>
    | ReturnType<typeof resetTasksScheduled>
    | ReturnType<typeof eventModalActiveId>
    | ReturnType<typeof resetEventCalendarDetail>
    | ReturnType<typeof calendarFilter>
    | ReturnType<typeof resetFilterLocationCalendar>
    | ReturnType<typeof calendarHomeComponentActiveView>
);
