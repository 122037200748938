import React, { useState,  useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    tabPanel: {
        display: 'none',
        '&.active': {
            display: 'block',
        },
    },
}));

const a11yProps = (index: number) => ({
    key: `scrollable-auto-tab-${index}`,
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
})

type TabsButtonProps = {
    tabs : Array<{name: string, children: React.ReactNode, label: string | React.ReactNode}>;
    onChangeTab?: (newValue: string) => void;
    isReRenderChildren?: boolean;
    variant?: "scrollable" | "standard" | "fullWidth";
    centered?: boolean;
}

export const TabsButton = ( { tabs = [], onChangeTab, isReRenderChildren = false, centered, variant = 'scrollable' }: TabsButtonProps) => {
    const classes = useStyles();
    const [value, setValue] = useState<number>(0);

    const handleChange = useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        if(onChangeTab){
            onChangeTab(tabs[newValue].name);
        }
    }, [onChangeTab, tabs] );

    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant={variant}
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                centered={centered}
            >
                {tabs.map((tab, index) => (
                    <Tab
                        label={tab.label}
                        {...a11yProps(index)}
                        key={uuidv4()}
                    />
                ))}
            </Tabs>
            {tabs.map((tab, index) => (
                <Box
                    className={`${classes.tabPanel} ${value === index ? 'active' : ''}`}
                    role="tabpanel"
                    aria-labelledby={`scrollable-auto-tab-${index}`}
                    id={`scrollable-auto-tabpanel-${index}`}
                    key={tab.name}
                    >
                        {isReRenderChildren ?
                            <>
                                {value === index && (
                                    <Box>
                                        {tab.children}
                                    </Box>
                                )}
                            </>
                        : tab.children}
                </Box>
            ))}
        </div>
    );
}
