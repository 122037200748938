import { StyledLayoutBodyColumn } from '../WelcomePageLayout';
import { Box, Typography } from '@material-ui/core';
import { t } from 'i18next';
import { NotificationsPage } from 'modules/notifications/NotificationsPage';

export const Notification = () => (
  <StyledLayoutBodyColumn>
    <Box display="flex" gridGap={8} alignItems="center">
      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{t('knocklabs.notifications')}</Typography>
    </Box>
    <Box style={{ overflow: 'auto' }} mt={2}>
      <NotificationsPage />
    </Box>
  </StyledLayoutBodyColumn>
)
