import React from 'react';
import { Box, Theme, Typography, styled } from '@material-ui/core';
import { Breakpoints } from 'utils/styles/constants';

const StyledWelcomePageWrapper = styled(Box)(() => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
}));

const StyledLayoutHeader = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        padding: theme.spacing(1.5, 2),
    },
}));

const StyledLayoutCover = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    margin: theme.spacing(3, 4),
    borderRadius: theme.spacing(.5),
    height: 'auto',
    maxHeight: 220,

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        padding: theme.spacing(1.5, 0),
        margin: theme.spacing(1.5, 0, 0, 0),
    },
}));

const StyledLayoutBody = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    padding: theme.spacing(3, 4),
    margin: theme.spacing(3, 4),
    borderRadius: theme.spacing(.5),
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: 24,

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        width: '100%',
        padding: theme.spacing(1.5, 2),
        marginLeft: 0,
        marginRight: 0,
        flexDirection: 'column',
        '& > *': {
            width: '100%'
        },
    },
}));

export const StyledLayoutBodyColumn = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    height: 'calc(100vh - 176px - 220px - 24px)',
    width: '100%',
    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
}));

const StrongWelcomeTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    ...getCommonStyles(theme),
}));

const getCommonStyles = (theme: Theme) => ({
    fontFamily: `'Inter', sans-serif`,
    fontStyle: 'normal',
    fontSize: theme.spacing(3),
    lineHeight: theme.spacing(4) + 'px',
    letterSpacing: 0,
});


export interface IWelcomePageLayoutProps {
    header: React.ReactNode;
    launchCover: React.ReactNode;
}

export const WelcomePageLayout: React.FC<React.PropsWithChildren<IWelcomePageLayoutProps>> = ({
    header,
    children,
    launchCover
}) => (
    <StyledWelcomePageWrapper>
        <StyledLayoutHeader>
            <StrongWelcomeTitle>
                {header}
            </StrongWelcomeTitle>
        </StyledLayoutHeader>

        <StyledLayoutCover>
            {launchCover}
        </StyledLayoutCover>

        <StyledLayoutBody>
            {children}
        </StyledLayoutBody>

    </StyledWelcomePageWrapper>
);
