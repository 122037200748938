
import React, { useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page,  } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Box, IconButton } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { useWindowDimensions } from 'modules/shared/hooks';

const workerUrl = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const MAX_ZOOM = 2;
const MIN_ZOOM = 0.3;
const INCREMENT_ZOOM = .1;
const MARGEN_WIDTH = 40;

export default function PDFViewer({ fileUrl } : { fileUrl: string } ) {
    const [numPages, setNumPages] = useState<number>(1);
    const [scale, setScale] = useState(MIN_ZOOM);
    const { width: widthW } = useWindowDimensions();
    const docRef = useRef<number>(widthW - MARGEN_WIDTH);

    useEffect(()=>{
        pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;
    },[])

    const onDocumentLoadSuccess = async (pdfObject: any) => {
        const page = await pdfObject.getPage(1);
        const [,, pdfWidth ] = page.view;
        docRef.current = pdfWidth > widthW ? (widthW - MARGEN_WIDTH) : pdfWidth;

        if(pdfWidth < widthW){
            setScale(1)
        }else{
            const scale = Math.round(((docRef.current / pdfWidth) * 100)) / 100;
            setScale(scale)
        }
        setNumPages(pdfObject.numPages);
    }

    const zoomIn = () => {
        if(MAX_ZOOM > scale && (docRef.current * scale < docRef.current * MAX_ZOOM)){
            const newScale = Math.round((scale + INCREMENT_ZOOM) * 100) / 100;
            setScale(newScale);
        }
      };
    
    const zoomOut = () => {
        if(scale !== MIN_ZOOM){
            setScale(Math.round((scale - INCREMENT_ZOOM) * 100) / 100);
        }
    };

    return (
        <Box>
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <div className="pages-pdf">
                    {numPages &&
                        Array.from({ length: numPages }, (_, index) => index + 1).map(
                        (pageNumber) => <Page
                            key={`number-${pageNumber}@${scale}`}
                            pageNumber={pageNumber} 
                            renderTextLayer={false}
                            scale={scale}
                            renderAnnotationLayer
                        />
                        )}
                </div>
            </Document>
                
            <div className="pdfviewer__controls">
                <IconButton onClick={zoomIn} disabled={scale === MAX_ZOOM}><ZoomInIcon/></IconButton>
                <IconButton onClick={zoomOut} disabled={scale === MIN_ZOOM}><ZoomOutIcon/></IconButton>
            </div>
        </Box>
    );
}